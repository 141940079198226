<template>
  <div class="columns is-multiline">
    <div class="column is-8 is-offset-2">
      <p class="title is-4 has-text-right">
        {{ classGroupName }}, {{ sessionTime }}
      </p>
      <p class="subtitle is-5 has-text-right">
        {{ `₦ ${amount}` }}
      </p>
    </div>
    <div class="column is-8 is-offset-2 px-0">
      <SdSelect type="text" label="Fee Item" v-model="feeItem">
        <option v-for="feeItem in feeItems" :key="feeItem.id">
          {{ feeItem.name }}
        </option>
      </SdSelect>
      <div class="buttons is-right mb-5">
        <button class="button is-small is-info" @click="add">
          Add Fee Item
        </button>
      </div>
    </div>
    <div
      class="column is-8 is-offset-2 card mb-3"
      v-for="feeTemplate in feeTemplates"
      :key="feeTemplate.id"
    >
      <div class="">{{ feeTemplate.feeItem.name }}</div>
      <div class="pb-0">{{ `₦ ${feeTemplate.feeItem.amount}` }}</div>
      <div class="buttons is-right mb-1">
        <a
          class="is-small has-text-danger"
          @click="initializeRemove(feeTemplate.id)"
          style="font-size: 1.1em; margin-left: 16px"
        >
          <fa-icon icon="trash-alt" size="lg" />
        </a>
      </div>
    </div>
    <!-- Delete -->
    <app-modal
      id="remove-fee-template-item"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdSelect from '@/components/SdSelect'
import { crudNotification } from '@/assets/js/notification'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Fee Template Info',
      feeTemplates: [],
      feeItems: [],
      classGroupName: '',
      feeItem: null,
      classGroupId: null,
      feeItemId: null,
      sessionTime: null,
      amount: '0.00',
      context: 'remove',
    }
  },
  apollo: {
    feeItems: {
      query: gql`
        query FeeItemsQuery($schoolId: ID!) {
          feeItems(schoolId: $schoolId) {
            id
            name
          }
        }
      `,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    feeItem(value) {
      this.feeItemId = this.feeItems.filter(
        (item) => item.name.trim() === value
      )[0].id
    },
    feeTemplates(data) {
      if (data.length === 0) return

      const amount = data.map((item) =>
        item.feeItem ? item.feeItem.amount : 0
      )
      this.amount = amount.reduce((a, b) => {
        return a + b
      })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Templates',
        route: `/school/${this.schoolId}/fee_templates`,
      },
      {
        name: 'Fee Items',
        route: `/school/${this.schoolId}/fee_items`,
      },
    ])

    const data = this.$store.getters.getRecord

    if (!data.hasOwnProperty('sessionTime')) {
      this.$router.push(`/school/${this.schoolId}/fee_templates`)
      return
    }

    this.classGroupId = data.classGroupId
    this.classGroupName = data.classGroupName
    this.sessionTime = data.sessionTime

    this.$apollo.addSmartQuery('feeTemplates', {
      query: gql`
        query FeeTemplateFeeItemsQuery(
          $classGroupId: ID!
          $sessionTime: String!
        ) {
          feeTemplateByClassGroupSessionTime(
            classGroupId: $classGroupId
            sessionTime: $sessionTime
          ) {
            id
            classGroup {
              id
              name
            }
            feeItem {
              id
              name
              itemType
              amount
            }
            sessionTime
          }
        }
      `,
      variables: {
        classGroupId: parseInt(this.classGroupId),
        sessionTime: this.sessionTime,
      },
      update: (data) => data.feeTemplateByClassGroupSessionTime,
    })
    this.$apollo.queries.feeTemplates.refetch()
  },
  components: {
    SdSelect,
  },
  methods: {
    initializeUpdate(id) {
      this.$router.push(`/school/${this.schoolId}/fee_item/${id}`)
    },
    initializeRemove(id) {
      this.id = parseInt(id)
      this.openModal()
    },
    add() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createFeeTemplateQuery(
              $classGroupId: Int!
              $feeItemId: Int!
              $sessionTime: String!
            ) {
              createFeeTemplate(
                input: {
                  classGroupId: $classGroupId
                  feeItemId: $feeItemId
                  sessionTime: $sessionTime
                }
              ) {
                feeTemplate {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            schoolId: parseInt(this.schoolId),
            classGroupId: parseInt(this.classGroupId),
            feeItemId: parseInt(this.feeItemId),
            sessionTime: this.sessionTime,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.createFeeTemplate.errors,
            'Successfully created.'
          ).then(() => {
            this.$apollo.queries.feeTemplates.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteFeeTemplateQuery($id: Int!) {
              deleteFeeTemplate(input: { id: $id }) {
                feeTemplate {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteFeeTemplate.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.feeTemplates.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
